import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getReportData } from "../api";
import PageLoader from "../components/UI/PageLoader";
import GeneratePDF from "../components/UI/GeneratePDF";
import ReportNotFound from "./ReportNotFound";

import ReportAMR from "./ReportAMR";
import ReportRMR from "./ReportRMR";

import classes from "./Report.module.css";
import StressReport from "./StressReport";
import CustomReportRMR from "./CustomReportRMR";
import CustomReportAMR from "./CustomReportAMR";

const Report = ({ svgConverter, isNativeApp }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [allPagesToVertical, setAllPagesToVertical] = useState(false);

  const params = useParams();
  const { id, type, lang } = params;

  useEffect(() => {
    window.location.replace(
      `https://welcome.mypnoe.com/reports?id=${id}&type=${type}&lang=${lang}`
    );
    // getReportData(id, type, lang, setData, setIsLoading, setError);
  }, [id, type, lang]);

  // console.log(data);

  return (
    <React.Fragment>
      {isLoading && <PageLoader />}
      {/*{data && !isNativeApp && (*/}
      {/*  <GeneratePDF*/}
      {/*    userName={data.PAGES.find((page) => page.INDEX === 1).SUBJECT_NAME}*/}
      {/*    type={type}*/}
      {/*    setAllPagesToVertical={setAllPagesToVertical}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{error && <ReportNotFound error={error} />}*/}
      {/*<div id="content" className={classes.content}>*/}
      {/*  {data &&*/}
      {/*    (type === "10" || type === "12" || type === "14") &&*/}
      {/*    data.TRAINER_MAIL === "rick@millerandeverton.co.uk" && (*/}
      {/*      <CustomReportAMR*/}
      {/*        data={data}*/}
      {/*        svgConverter={svgConverter}*/}
      {/*        reportType={type}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  {data &&*/}
      {/*    (type === "10" || type === "12" || type === "14") &&*/}
      {/*    data.TRAINER_MAIL !== "rick@millerandeverton.co.uk" && (*/}
      {/*      <ReportAMR*/}
      {/*        data={data}*/}
      {/*        svgConverter={svgConverter}*/}
      {/*        reportType={type}*/}
      {/*        allPagesToVertical={allPagesToVertical}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  {data &&*/}
      {/*    (type === "11" || type === "13" || type === "15") &&*/}
      {/*    data.TRAINER_MAIL === "rick@millerandeverton.co.uk" && (*/}
      {/*      <CustomReportRMR*/}
      {/*        data={data}*/}
      {/*        svgConverter={svgConverter}*/}
      {/*        reportType={type}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  {data &&*/}
      {/*    (type === "11" || type === "13" || type === "15") &&*/}
      {/*    data.TRAINER_MAIL !== "rick@millerandeverton.co.uk" && (*/}
      {/*      <ReportRMR*/}
      {/*        data={data}*/}
      {/*        svgConverter={svgConverter}*/}
      {/*        reportType={type}*/}
      {/*        allPagesToVertical={allPagesToVertical}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  {data && type === "16" && (*/}
      {/*    <StressReport*/}
      {/*      data={data}*/}
      {/*      svgConverter={svgConverter}*/}
      {/*      reportType={type}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</div>*/}
    </React.Fragment>
  );
};

export default Report;
