import classes from "./ReportNotFound.module.css";

import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const ReportNotFound = ({ error }) => {
  const errorMessageHandler = (error) => {
    let message;
    switch (error) {
      case "Request failed with status code 422":
        message = "The report type was not found";
        break;
      case "en":
        message = "The report lang was not found";
        break;
      case "Request failed with status code 400":
        message = "The report id was not found";
        break;
      default:
        message = "An error has occurred";
    }
    return message;
  };

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={logo} alt="PNOE logo" />
      </div>
      <div className={classes.text1}>{errorMessageHandler(error)}</div>
      <div className={classes.text2}>
        Please reach out to our support team at{" "}
        <Link
          className={classes.link}
          onClick={() => (window.location = "mailto:support@pnoe.com")}
        >
          support@pnoe.com
        </Link>{" "}
        to assist you.
      </div>
    </div>
  );
};

export default ReportNotFound;
