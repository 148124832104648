import { useCallback } from "react";
import { Route, Routes } from "react-router-dom";

import html2canvas from "html2canvas";

import Report from "./reports/Report";
import ReportNotFound from "./reports/ReportNotFound";

import "./fonts/AktivGroteskEx_A_Bd-normal";
import "./fonts/AktivGroteskEx_A_Lt-normal";
import "./fonts/AktivGroteskEx_A_Md-normal";
import "./fonts/AktivGroteskEx_A_Rg-normal";
import "./fonts/AktivGroteskEx_A_XBd-normal";
import "./fonts/Poppins-Light-normal";

import classes from "./App.module.css";

const App = () => {
  //Function to convert svg element to image
  const svgConverter = useCallback(async (content, result, imgWidth) => {
    const element = document.getElementById(content),
      canvas = await html2canvas(element, {
        useCORS: true,
        backgroundColor: "transparent",
        allowTaint: true,
        scale: 1.5,
      });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpg");
    img.width = imgWidth;
    document.getElementById(result).appendChild(img);
  }, []);

  return (
    <div className={classes.app}>
      <Routes>
        <Route
          exact
          path="/id=:id/type=:type/lang=:lang"
          element={<Report svgConverter={svgConverter} isNativeApp={false} />}
        />
        <Route
          exact
          path="/id=:id/type=:type/lang=:lang/isNativeApp=true"
          element={<Report svgConverter={svgConverter} isNativeApp={true} />}
        />
        <Route
          path="*"
          element={<ReportNotFound error="An error has occurred." />}
        />
      </Routes>
    </div>
  );
};

export default App;
